import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { t } from "@lingui/macro"
import { I18nProvider, I18n } from "@lingui/react"
import catalogEs from "../locales/es/messages"
import catalogBr from "../locales/br/messages"
import catalogFr from "../locales/fr/messages"
import catalogIt from "../locales/it/messages"
import catalogDe from "../locales/de/messages"
const letters = [
  "#",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
]

const paths = {
  it: "/it/che-cose",
  br: "/br/o-que-e",
  fr: "/fr/quest-ce-que",
  de: "/de/was-ist",
  es: "/que-es",
}

export default props => (
  <>
    <I18nProvider
      language={props.pageContext.lang}
      catalogs={{
        //en: catalogEn,
        es: catalogEs,
        it: catalogIt,
        de: catalogDe,
        br: catalogBr,
        fr: catalogFr,
      }}
    >
      <Layout
        image="/android-chrome-512x512.png"
        lang={props.pageContext.lang}
        title={props.data.allContentfulTermsStartingWith.edges[0].node.title}
        description={
          props.data.allContentfulTermsStartingWith.edges[0].node.description
        }
        paths={paths}
        pageType="terms"
      >
        <div className="container">
          <h1 className="mt-5">
            {props.data.allContentfulTermsStartingWith.edges[0].node.header}
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html:
                props.data.allContentfulTermsStartingWith.edges[0].node
                  .subheader.childMarkdownRemark.html,
            }}
          ></div>

            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
            <ins className="adsbygoogle"
                style={{display:"block", textAlign:"center"}}
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-8800979330857975"
                data-ad-slot="6169923305"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>

          {letters.map(letter => {
            return (
              <div className="row" id={letter}>
                <div className="col-12">
                  <h2 className="py-5 mt-5">{letter}</h2>
                </div>
                {props.data.allContentfulTerm.nodes.map(node => {
                  return (
                    node.lang === props.pageContext.lang &&
                    node.startingLetter.toUpperCase() ===
                      letter.toUpperCase() && (
                      <div className="col-lg-3 col-sm-6">
                        <I18n>
                          {({ i18n }) => (
                            <Link
                              to={i18n._(t`/que-es`) + "/" + node.slug + "/"}
                              className="single-col-max mx-auto"
                              style={{ color: "dimgrey" }}
                            >
                              {node.h1}
                            </Link>
                          )}
                        </I18n>
                      </div>
                    )
                  )
                })}
              </div>
            )
          })}
        </div>
      </Layout>
    </I18nProvider>
  </>
)

export const query = graphql`
  query($lang: String!) {
    allContentfulTermsStartingWith(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          header
          lang
          description
          slug
          subheader {
            childMarkdownRemark {
              html
            }
          }
          title
        }
      }
    }
    allContentfulTerm {
      nodes {
        lang
        slug
        startingLetter
        h1
      }
    }
  }
`
